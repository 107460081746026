var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'question-navigation-item',
    {
      '--correct': _vm.answered && _vm.correct,
      '--wrong': _vm.answered && !_vm.correct,
      '--active': _vm.active,
    }
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', _vm.number)}}},[_c('span',{staticClass:"question-navigation-item__number"},[_vm._v(" "+_vm._s(_vm.number)+" ")]),(_vm.answered)?_c('div',{staticClass:"question-navigation-item__icon"},[_c('icon',{attrs:{"type":_vm.icon}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }