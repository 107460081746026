import http from './http'

export default {
  postProposedAssignment: (subjectId) => http.post(
    `subjects/${subjectId}/proposed-assignments`
  ),
  getProposedAssignments: (subjectId) => http.get(
    `subjects/${subjectId}/proposed-assignments`
  ),
  getProposedAssignmentInfo: (subjectId) => http.get(`/subjects/${subjectId}/proposed-assignment-info`),
}
