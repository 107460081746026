<template>
  <div class="question-navigation">
    <div class="question-navigation__wrapper">
      <template v-if="loading">
        <skeleton-loader
          v-for="index in 5"
          :key="index"
          class="question-navigation-item"
          width="32px"
          height="32px"
          border-radius="8px"
        />
      </template>
      <template v-else>
        <QuestionNavigationItem
          v-for="(item, index) in questions.data"
          :key="index"
          :tabindex="index + 1"
          :number="index + 1"
          :disabled="question.loading"
          :active="index === activeQuestionIndex"
          :answered="questionIsAnswered(item)"
          :correct="questionIsCorrect(item)"
          @click="$emit('navigate', index)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { QUESTION_STATES } from '@/service/questions'
import QuestionNavigationItem from './QuestionNavigationItem'

export default {
  name: 'QuestionNavigation',
  components: {
    QuestionNavigationItem,
  },
  props: {
    activeQuestionIndex: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    ...mapGetters([
      'question',
      'questions',
    ]),
    loading() {
      return this.questions.loading
    },
  },
  methods: {
    questionIsActive(question) {
      return question.id === this.question.data.id
    },
    questionIsAnswered(question) {
      return question.status !== QUESTION_STATES.NOT_ANSWERED
    },
    questionIsCorrect(question) {
      if (question.status === QUESTION_STATES.CORRECT) return true
      if (question.status === QUESTION_STATES.WRONG) return false

      return undefined
    },
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="scss" scoped>
.question-navigation {
  background: linear-gradient(180deg, rgba(0,0,0, 0.1) 0, rgba(0,0,0,0) 3px), linear-gradient(0deg, rgba(0,0,0, 0.15) 0, #F7F7F7 2px);
  width: 100%;
  padding: 0 $size-xs;
  overflow-x: auto;

  &__wrapper {
    @include flex-center-start;
    padding: $size-xs 0;
    margin: 0 auto;
    min-width: 1032px;
    max-width: max-content;

    @include mq_xl {
      min-width: calc(100% - 64px);
    }

    @include mq_l {
      min-width: calc(100% - 64px);
    }

    @include mq_m {
      min-width: calc(100% - 48px);
    }

    @include mq_s {
      min-width: calc(100% - 16px);
    }

    @include mq_xs {
      min-width: calc(100% - 16px);
    }

    ::v-deep .question-navigation-item {
      @include space-inline($size-xs);
      flex-shrink: 0;
    }
  }
}
</style>
<!-- eslint-enable max-len -->
