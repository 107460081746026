<template>
  <svg
    class="sas-spinner"
    viewBox="25 25 50 50"
    :class="customSize ? '' : `--${size}`"
    :stroke="color"
    :stroke-width="strokeWidth"
    :style="{ height: customSize }"
  >
    <circle />
  </svg>
</template>

<script>
/** this.trackBy ? value[this.trackBy] : value
 * @example ../../docs/components/examples/Spinner.md
 */
export default {
  name: 'Spinner',
  props: {
    /**
     * Size of the spinner
     * `small, medium, large`
     */
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => value.match(/(small|medium|large)/),
    },
    /**
     * Custom size of the spinner in pixels
     */
    customSize: {
      type: [ Number, String ],
      default: null,
      required: false,
    },
    /**
     * Stroke color of spinner
     */
    color: {
      type: String,
      default: 'currentColor',
      required: false,
    },
    /**
     * Stroke width of spinner
     */
    strokeWidth: {
      type: [ String, Number ],
      default: 3,
      required: false,
    },
  },
}
</script>

<style lang="sass">
// --------------------------------------------------------------
// Variables
// --------------------------------------------------------------
$spinner-size-l: $size-xxl
$spinner-size-m: $size-l
$spinner-size-s: $size-s

// --------------------------------------------------------------
// Base
// --------------------------------------------------------------
.sas-spinner
  +transition($speed-fast)
  transform-origin: center
  animation: rotate 2s linear infinite

  // --------------------------------------------------------------
  // Sizes
  // --------------------------------------------------------------
  &.--large
    width: $spinner-size-l
  &.--medium
    width: $spinner-size-m
  &.--small
    width: $spinner-size-s
    stroke-width: 4

  // --------------------------------------------------------------
  // Circle
  // --------------------------------------------------------------
  circle
    fill: none
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
    stroke-linecap: round
    animation: dash 1.5s ease-in-out infinite
    cx: 50
    cy: 50
    r: 20

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------
@keyframes rotate
  100%
    transform: rotate(360deg)

@keyframes dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0

  50%
    stroke-dasharray: 90, 200
    stroke-dashoffset: -35px

  100%
    stroke-dashoffset: -125px
</style>
