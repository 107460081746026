import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'subject',
    ]),
    subjectStyleHeader() {
      if (this.subject.loading) {
        return { backgroundColor: '#e0e4ea' }
      }

      if (this.subject.data) {
        return { backgroundColor: this.subject.data.color }
      }

      return {}
    },
  },
}
