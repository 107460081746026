<template>
  <div
    :class="[
      'question-bar',
      { '--light': lightMode },
    ]"
  >
    <div
      class="question-bar__header"
      :style="customStyle"
    >
      <wrapper>
        <div class="question-bar__header__wrapper">
          <div
            v-if="loading"
            class="question-bar__header__title"
          >
            <skeleton-loader
              width="250px"
              height="20px"
            />
          </div>
          <div
            v-else
            class="question-bar__header__title"
          >
            <h6>
              {{ assignmentTitle }}
            </h6>
            <span>
              {{ assignmentSubtitle }}
            </span>
          </div>
          <s-button
            v-if="['x_small', 'small'].includes($mq)"
            icon="x"
            variation="tertiary"
            @click="$emit('close')"
          />
          <s-button
            v-else
            icon-left="x"
            variation="tertiary"
            @click="$emit('close')"
          >
            {{ $t('commons.close') }}
          </s-button>
        </div>
      </wrapper>
    </div>
    <QuestionNavigation
      v-if="hasActiveQuestionIndex"
      :active-question-index="activeQuestionIndex"
      @navigate="$emit('navigate', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import QuestionNavigation from './QuestionNavigation'

export default {
  name: 'QuestionBar',
  components: {
    QuestionNavigation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    lightMode: Boolean,
    loading: {
      type: Boolean,
      required: true,
    },
    activeQuestionIndex: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    ...mapGetters([
      'questions',
      'question',
    ]),
    assignmentTitle() {
      if (this.title) {
        return this.title
      }

      return this.question.data && this.question.data.assignment
        ? this.question.data.assignment.title
        : ''
    },
    assignmentSubtitle() {
      if (this.subtitle) {
        return this.subtitle
      }

      return this.question.data && this.question.data.assignment
        ? this.question.data.assignment.subtitle
        : ''
    },
    hasActiveQuestionIndex() {
      return this.activeQuestionIndex >= 0
    },
  },
}
</script>

<style lang="scss" scoped>
.question-bar {
  &__header {
    padding: $size-s 0;

    &__wrapper {
      @include flex-space-between;
    }

    &__title {
      span {
        color: $color-ink-light;
      }

      .sas-skeleton-loader:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.--light &__header {
    &__title {
      color: $color-white;

      span {
        color: $color-white;
        opacity: 0.8
      }
    }

    .sas-button {
      color: $color-white;
    }
  }
}
</style>
