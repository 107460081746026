<template>
  <div class="search-proposed-assignment">
    <QuestionBar
      :loading="!subject.ready"
      :light-mode="questionBarLightMode"
      :title="subjectName"
      :subtitle="$t('searchProposedAssignment.subtitle')"
      :custom-style="subjectStyleHeader"
      @close="close"
    />
    <EmptyState
      class="search-proposed-assignment__state"
      :title="$t('searchProposedAssignment.empty.title')"
      image="search-questions"
    >
      <Spinner size="medium" />
    </EmptyState>
  </div>
</template>

<script>
import subjectStyleHeader from '@/mixins/subjectStyleHeader'
import subject from '@/mixins/subject'
import mediaQueries from '@/mixins/mediaQueries'
import EmptyState from '@/components/EmptyState'
import Spinner from '@/components/Spinner'
import QuestionBar from '@/views/Assignment/partials/QuestionBar'
import proposedAssignmentApi from '@/service/proposedAssignment'

export default {
  name: 'SearchProposedAssignment',
  components: {
    QuestionBar,
    Spinner,
    EmptyState,
  },
  mixins: [ mediaQueries, subject, subjectStyleHeader ],
  props: {
    questionBarLightMode: {
      type: Boolean,
    },
  },
  computed: {
    subjectName() {
      return this.subject.data?.name
    },
  },
  mounted() {
    this.fetchProposedAssignment()
  },
  methods: {
    async fetchProposedAssignment() {
      const {
        data: { questionnaireCode },
      } = await proposedAssignmentApi.postProposedAssignment(this.subjectId)

      this.$router.replace({
        name: 'lecture-question',
        params: {
          ...this.$route.params,
          questionnaireCode,
        },
      })
    },
    close() {
      this.$router.push({
        name: 'lecture-assignments',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.search-proposed-assignment {
  background: $color-white;
  width: 100%;

  &__state {
    min-height: calc(100vh - #{$menu-height});
    @include flex-center;
  }
}

::v-deep .question-bar__header {
  &__title {
      color: $color-white;

      span {
        color: $color-white;
        opacity: 0.8
      }
    }

    .sas-button {
       color: $color-white;
    }
}
</style>
